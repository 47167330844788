import api from "../../../utility/Utils";

export const authService = {
  loginLastVersion,
  getUserInfoBase,
  invalideToken,
};

function loginLastVersion(user_mail, password) {
  let data = { userName: user_mail, password: password };
  return api
    .post("account/login", data)
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          if (response.data.isEmployer) {
            //console.log(response)
            return Promise.resolve(response);
          } else {
            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("user", response.data);
            return Promise.resolve(response);
          }
        } else {
          return Promise.resolve(response);
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function getUserInfoBase() {
  return api
    .get("candidate/base")
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          return Promise.resolve(response);
        } else {
          return Promise.resolve(response);
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function invalideToken() {
  //token kontrolü

  var token = localStorage.getItem("token");
  var sendData = {
    token: token === null || token === undefined ? null : token,
  };
  return api
    .post("/account/validatetoken", sendData)
    .then((response) => {
      console.log("response invalid* * " + JSON.stringify(response));
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
