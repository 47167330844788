import React from "react";
import noMatchFound from "../../image/noMatchFound.svg";

const NotFoundRoutePage = ({ title }) => {
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={noMatchFound}
              style={{
                height: "80px",
                textAlign: "center",
              }}
              alt=""
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                { "İlgili Sayfa Bulunamadı" }
              </p>
              {
                <div
                  className="card"
                  style={{
                    border: "none",
                  }}
                >
                 
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundRoutePage;
