import { setUser } from "../../../configs/cookie";
import { toast } from "react-toastify";
import { authService } from "../services/authServices";
import { candidateAuth } from "../reducer/auth";

export const UserAction = {
  saveLUser,
  candidateLogin,
};

function saveLUser(response) {
  setUser(response.data);
  return Promise.resolve(response.data);
}
function candidateLogin(user_mail, password, navigate,location, dispatch, ) {
  return (dispatch) => {
    authService.loginLastVersion(user_mail, password).then(
      async (resData) => {
        if (resData) {
          if (resData.succeeded === true) {
            dispatch(candidateAuth(resData.data.accessToken));
            navigate(location.pathname || "/");
         
            toast.success("Giriş Başarılı", {
              autoClose: 1000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          } else {
            dispatch(candidateAuth(null));

            toast.error(resData.error.message, {
              autoClose: 5000,
              onClose: () => {
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              },
            });
          }
        }
      },
      (error) => {
        dispatch(candidateAuth(null));
      }
    );
  };
}
