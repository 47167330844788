import React, { useState } from "react";
import "../../styles/conferenceItem.css";
import moment from "moment";
import demandIcon from "../../image/deamansIcon.svg";
import SetNameForVideoConference from "../Layout/Modal/SetNameForVideoConference";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, useDisclosure } from "@nextui-org/react";

function TabelItem({
  companyName,
  meetingDate,
  employerProfileImageUrl,
  item,
}) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "#F5F5F5";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  function handleClose() {
    setShow(false);
  }
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false); // Resim yüklenemediğinde state'i güncelle
  };

  const [error, setError] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        justifyItems: "center",
        margin: "0px 30px 0px 30px",
      }}
      id="itemCard"
    >
      <div
        // onClick={onOpen}
        onClick={() => {
          var roomText = item.matchId.toString();
          handleClose();
          const dataMap = new Map();
          dataMap.set("roomName", roomText);
          dataMap.set("displayName", item.companyAlias);
          dataMap.set("companyName", item.companyAlias);
          // ----
          dataMap.set("meetingRoom", null);
          dataMap.set("videoConferansId", null);
          dataMap.set("meetingEmployerId", null);
          dataMap.set("meetingMatchId", item.matchId);
          dataMap.set("meetingJobId", null);
          //   dataMap.set("meetingCandidateId", item.candidateId);
          dataMap.set("meetingMeetingDateTime", item.meetingDate);
          dataMap.set("companyAlias", item.companyAlias);
          dataMap.set("meetingCandidateNameSurname", item.candidateNameSurname);
          dataMap.set("meetingPositionName", item.positionName);
          navigate("/videoConference", { state: { dataMap } });
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          border: "1px solid #F5F5F5",
          height: "70px",
          position: "relative",
          borderRadius: "10px",
          alignItems: "center",
          width: "100%",
          margin: "8px",
          justifyContent: "space-between",
          cursor: "pointer",
          backgroundColor: getBackgroundColor(), // Dinamik arka plan rengi
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "center",
            marginLeft: "0px",
            paddingLeft: "0px",
          }}
        >
          <div>
            <div className="flex gap-4 items-center m-l m-2">
              <div
                style={{
                  width: "55px",
                  height: "55px",
                  marginTop: "10px",
                }}
              >
                {error === false ? (
                  <img
                    src={item.employerProfileImageUrl}
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "3px solid grey",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                    onError={() => {
                      setError(true);
                    }}
                  />
                ) : (
                  <i
                    class="fa-regular fa-building"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "3px solid orange",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      backgroundSize: "cover",
                      fontSize: "25px",
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="item-title">{companyName}</div>
          </div>
        </div>
        <div className="position-title">{}</div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div className="hire-status-title">
            {meetingDate && (
              <p className="matchCreateDate">
                Video Konferans Tarihi:{" "}
                {moment(meetingDate).format("DD/MM/YYYY~HH:mm")}
              </p>
            )}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 0,
            height: 0,
            borderLeft: "30px solid transparent",
            borderTop: "30px solid transparent",
            borderRight: "30px solid teal",
            borderRadius: "0px 0px 8px",
          }}
        ></div>
        <SetNameForVideoConference
          isOpen={isOpen}
          title={companyName}
          onOpenChange={onOpenChange}
          setShow={setShow}
          approval={(companyName) => {
            if (companyName) {
              var roomText = item.matchId.toString();
           //   alert(" item.candidateNameSurname* * " + JSON.stringify(item));

              const dataMap = new Map();
              dataMap.set("roomName", roomText);
              dataMap.set("displayName", item.companyAlias);
              dataMap.set("companyName", item.companyAlias);

              navigate("/videoConference", { state: { dataMap } });
            }
          }}
        />
      </div>
    </div>
  );
}

export default TabelItem;
