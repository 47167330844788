import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link,
} from "@nextui-org/react";

import { useForm } from "react-hook-form";

function SetNameForVideoConference({
  isOpen,
  onOpenChange,
  approval,
  title,
  setShow,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  function setNameFunction(form) {
    approval(form.conferenceShowCompanyName);
  }
  useEffect(() => {
    if (isOpen) {
      setValue("conferenceShowCompanyName", title);
    }
  }, [isOpen]);
  return (
    <>
      <div>
        <Modal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          placement="top-center"
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  Video Konferanst görünecek İsmi Belirle
                </ModalHeader>
                <ModalBody>
                  <form onSubmit={handleSubmit(setNameFunction)}>
                    <Input
                      name="conferenceShowCompanyName"
                      {...register("conferenceShowCompanyName", {
                        required: true,
                      })}
                      defaultValue={title}
                      label="Video Konferanst görünecek İsim"
                      type="text"
                      variant="bordered"
                    />{" "}
                    {errors.conferenceShowCompanyName && (
                      <div className="text-danger fs-12">
                        {"Firma İsmi Boş Olamaz"}
                      </div>
                    )}
                  </form>
                </ModalBody>
                <ModalFooter
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form onSubmit={handleSubmit(setNameFunction)}>
                    <Button
                      color="primary"
                      type="submit"
                      size="md"
                      style={{
                        width: "300px",
                      }}
                    >
                      Katıl
                    </Button>
                  </form>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  const { approval, show, title } = props;
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  function setNameFunction(form) {
    approval(form.conferenceShowCompanyName);
  }
  useEffect(() => {
    if (show) {
      setValue("conferenceShowCompanyName", title);
    }
  }, [show]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", justifyContent: "center" }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h3 style={{ color: "#001542", fontSize: "20px" }}></h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          paddingTop: "0px",
          marginTop: "0px",
        }}
      >
        <form onSubmit={handleSubmit(setNameFunction)}>
          <input
            type="text"
            className="form-control"
            id="inputClass"
            name="conferenceShowCompanyName"
            {...register("conferenceShowCompanyName", { required: true })}
            placeholder="Video Konferanst görünecek İsim"
          ></input>
          {errors.conferenceShowCompanyName && (
            <div className="text-danger fs-12">{"Firma İsmi Boş Olamaz"}</div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
        }}
      >
        {" "}
        <form onSubmit={handleSubmit(setNameFunction)}>
          <Button
            as="input"
            type="submit"
            value="Katıl"
            style={{
              borderRadius: "8px",
              fontSize: "15px",
              padding: "15px",
              width: "300px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              backgroundColor: "#001542",
              border: "none",
            }}
          />
        </form>
      </Modal.Footer>
    </Modal>
  );
}
export default SetNameForVideoConference;
