import React from "react";
import "./loading.css";
const LoadingDotsAnimation = () => {
  return (
    <div id="workingAnimation">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default LoadingDotsAnimation;
