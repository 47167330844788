import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import { useSelector } from "react-redux";
import { NextUIProvider } from "@nextui-org/react";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import LoadingDotsAnimation from "./LoadingDotsAnimation";
import version from "../package.json";
import CacheBuster from "react-cache-buster";
import ProtectedRoute from "./Route/ProtectedRoute";
import NavBarMenu from "./pages/Navbar/NavBarMenu";
import { authService } from "./pages/toolkit/services/authServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeToken } from "./configs/cookie";
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./pages/auth/login/Login")), 500);
  });
});

function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [navVisible, showNavbar] = useState(false);
  const isProduction = process.env.NODE_ENV === "production";
  const [validToken, setValidToken] = useState(null);
  useEffect(() => {
    if (isLoggedIn) {
      authService
        .invalideToken()
        .then((res) => {
          if (res) {
            if (res.succeeded === true) {
              // alert("response * *  token * * * " + JSON.stringify(res));
              setValidToken(true);
            } else {
              if (res.error.code === 154) {
                setValidToken(false);
                toast.info(
                  "Oturum Süreniz dolmuştur,Lütfen tekrar giriş yapınız"
                );
                removeToken();
                window.location.reload();
              } else if (res.error.code === 155) {
                removeToken();
                window.location.reload();
              }
            }
          }
        })

        .catch((e) => {});
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (validToken) {
    } else {
    }
  }, [validToken]);

  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingDotsAnimation />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        {isLoggedIn ? (
          <NavBarMenu />
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
