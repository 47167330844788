import { createSlice } from "@reduxjs/toolkit";
    const storedToken = localStorage.getItem("token");

const initialState = {
  isLoggedIn: storedToken == null || storedToken === undefined ? false : true,
  token: storedToken == null || storedToken === undefined ? null : storedToken,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    candidateAuth(state, action) {
      if (action.payload) {
        state.isLoggedIn = true;
        state.token = action.payload;
      }
    },
  },
});

export const { candidateAuth } = authSlice.actions;
export default authSlice.reducer;
