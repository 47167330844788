import React from 'react'
import NotFoundRoutePage from '../pages/widgets/NotFoundRoutePage';

const NotFoundPage = () => {
  return (
    <div>
      <div
        className="card"
        style={{
          width: "100%",
          border: "none",
          borderRadius: "12px",
          margin: "0px",
          padding: "0px",
        }}
      ></div>
      <div
        className="card-body"
        style={{
          width: "100%",
        }}
      >
        <NotFoundRoutePage title={""} />
      </div>
    </div>
  );
}

export default NotFoundPage
