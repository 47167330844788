import React from 'react'
import noMatchFound from "../../image/noMatchFound.svg";

const MatchNotFound = ({ title, newMatch }) => {
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={noMatchFound}
              style={{
                height: "80px",
                textAlign: "center",

              }}
              alt=''
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {newMatch&&newMatch === true ? "Toplantı Bulunamadı" : title}
              </p>
              {newMatch && (
                <div className="card" style={{
                  border:"none"
                }}>
                  <p
                    style={{
                      color: "#001542",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "13px",
                      fontWeight: 500,
                      marginLeft: "20px",
                      marginRight: "20px",
                      border:"none"
                    }}
                  >
                    Profiliniz ile eşleşme olduğunda size bildirimler
                    göndereceğiz.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchNotFound