import cookie from "js-cookie";

export const setCookie = (key, value, expires = 360) => {
  if (process.browser) {
    return cookie.set(key, value, {
      expires: expires,
      path: "/",
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key);
    return true;
  }
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(";")
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split("=")[1];
};

const AUTH_COOKIE_TOKEN = "bitonis_candidate_token";

export const setToken = (value) => {
  return setCookie(AUTH_COOKIE_TOKEN, value);
};

export const removeToken = () => {
  // return removeCookie(AUTH_COOKIE_TOKEN);
  return localStorage.clear();
};

export const getToken = () => {
  return localStorage.getItem("token");
 // return getCookieFromBrowser(AUTH_COOKIE_TOKEN);
};

const USER_COOKIE = "_user";

export const setUser = (value) => {
  return setCookie(USER_COOKIE, value);
};

export const removeUser = () => {
  return removeCookie(USER_COOKIE);
};

export const getUser = () => {
  return getCookieFromBrowser(USER_COOKIE);
};

const TIMEOUT = "_timeout_";

export const setCookieTimeout = (key, day, value = true) => {
  return setCookie(TIMEOUT + key, value, day);
};

export const removeCookieTimeout = (key) => {
  return removeCookie(TIMEOUT + key);
};

export const getCookieTimeout = (key) => {
  return getCookieFromBrowser(TIMEOUT + key);
};

export const setOneStart = (value) => {
  return setCookie("onestart", value);
};

export const getOneStart = () => {
  return getCookieFromBrowser("onestart");
};
