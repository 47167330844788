import api from "../../../utility/Utils";

export const VideoConferenceService = {
  getVideoConferenceList,
  getInfoCandidate,
  videoConfJoined,
  videoConfClosed,
};
function getVideoConferenceList() {
  var sendData = { pageNumber: 1, pageSize: 100, status: 3 };
  return api
    .post("/candidate/employee/match", sendData)
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          return Promise.resolve(response);
        } else {
          return Promise.resolve(response);
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getInfoCandidate() {
  return api
    .get("/candidate/base")
    .then((response) => {
      if (response) {
        if (response.succeeded === true) {
          return Promise.resolve(response);
        } else {
          return Promise.resolve(response);
        }
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


function videoConfJoined(
  meetingJobId,
  meetingEmployerId,
  meetingCandidateId,
  videoConferansId,
  meetingMatchId,
  meetingMeetingDateTime,
  meetingRoom,
  meetingCandidateNameSurname,
  meetingPositionName,
  meetingCompanyAlias
) {
  var sendData = {
    jobId: meetingJobId,
    employerId: meetingEmployerId,
    candidateId: meetingCandidateId,
    videoConferansId: videoConferansId,
    matchId: meetingMatchId,
    meetingDateTime: meetingMeetingDateTime,
    meetingRoom: meetingRoom,
    candidateNameSurname: meetingCandidateNameSurname,
    positionName: meetingPositionName,
    companyAlias: meetingCompanyAlias,
  };
  return api
    .post("/videoconf/participation/joined", sendData)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function videoConfClosed(videoConfId) {
  return api
    .post("/videoconf/participation/left/" + videoConfId)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
