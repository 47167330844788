import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from "../../toolkit/action/auth";
import { useNavigate, Link, useLocation, Navigate } from "react-router-dom";
import "./login.css";
import { Form, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ButtonGroup, Card, Button } from "@nextui-org/react";
import { inputStyle } from "../../widgets/customStyleInline";

// Daha sonra bu ikonu kullanabilirsiniz

const Login = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const location = useLocation();
  const onSubmit = (formData) => {
    dispatch(
      UserAction.candidateLogin(
        formData.phoneNumber,
        formData.password,
        navigate,
        location,
        dispatch
      )
    );
  };

  return (
    <div>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Card style={{
        margin: "0px",
        padding: "0px",
        borderRadius:"0px"
      }}>
        <div className="Auth-form-container">
          <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">İş Arayan Girişi</h3>
              <h6
                style={{
                  fontSize: "14px",
                  color: "grey",
                  fontWeight: "550px",
                }}
              >
                Aşağıdaki Bilgileri Girerek Oturum Açınız
              </h6>
              <div className="form-group mt-3">
                <label>Telefon No</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="05xxxx..."
                  name="phoneNumber"
                  maxLength={11}
                  {...register("phoneNumber", { required: true })}
                  style={inputStyle}
                />
                {errors.phoneNumber && (
                  <div
                    className="text-danger"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Telefon Numarası zorunludur
                  </div>
                )}
              </div>
              <div className="form-group mt-3">
                <label>Şifre</label>
                <div style={{ position: "relative" }}>
                  {" "}
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control mt-1"
                    style={inputStyle}
                    {...register("password", { required: true })}
                    placeholder="Şifre"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </span>
                </div>
                {errors.password && (
                  <div
                    className="text-danger fs-12"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Şifre zorunludur
                  </div>
                )}{" "}
              </div>
              <div className="d-grid gap-2 mt-3">
                <Button
                  type="submit"
          
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#001542",
                    width: "100%",
                    height: "55px",
                    color: "white",
                  }}
                >
                  Giriş Yap
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default Login;
