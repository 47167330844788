import React, { useEffect, useState } from "react";
import MatchNotFound from "../widgets/MatchNotFound";
import ConferenceItem from "../widgets/ConferenceItem";
import "../../styles/videoConference.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import TabelItem from "../widgets/TabelItem";
import LoadingDotsAnimation from "../widgets/LoadingDotsAnimation";
import { Button, NextUIProvider } from "@nextui-org/react";
import { VideoConferenceService } from "../toolkit/services/videoConferenceServices";
import { Select, SelectItem } from "@nextui-org/react";
export const animals = [
  {
    label: "Cat",
    value: "cat",
    description: "The second most popular pet in the world",
  },
  {
    label: "Dog",
    value: "dog",
    description: "The most popular pet in the world",
  },
  {
    label: "Elephant",
    value: "elephant",
    description: "The largest land animal",
  },
  { label: "Lion", value: "lion", description: "The king of the jungle" },
  { label: "Tiger", value: "tiger", description: "The largest cat species" },
  {
    label: "Giraffe",
    value: "giraffe",
    description: "The tallest land animal",
  },
  {
    label: "Dolphin",
    value: "dolphin",
    description: "A widely distributed and diverse group of aquatic mammals",
  },
  {
    label: "Penguin",
    value: "penguin",
    description: "A group of aquatic flightless birds",
  },
  {
    label: "Zebra",
    value: "zebra",
    description: "A several species of African equids",
  },
  {
    label: "Shark",
    value: "shark",
    description:
      "A group of elasmobranch fish characterized by a cartilaginous skeleton",
  },
  {
    label: "Whale",
    value: "whale",
    description: "Diverse group of fully aquatic placental marine mammals",
  },
  {
    label: "Otter",
    value: "otter",
    description: "A carnivorous mammal in the subfamily Lutrinae",
  },
  {
    label: "Crocodile",
    value: "crocodile",
    description: "A large semiaquatic reptile",
  },
];

const VideoConferenceList = () => {
  const [videoConferenceList, setVideoConferenceList] = useState(null);

  useEffect(() => {
    getVideoConferenceList();
  }, []);

  function getVideoConferenceList() {
    setVideoConferenceList([]);
    VideoConferenceService.getVideoConferenceList().then(
      (conferenceData) => {
        if (conferenceData && conferenceData.succeeded === true) {
          if (conferenceData.data) {
            if (conferenceData.data.pagedData.length > 0) {
              conferenceData.data.pagedData.map((val) => {
                return setVideoConferenceList((prev) => [...prev, val]);
              });
            } else {
              setVideoConferenceList(null);
            }
          } else {
            setVideoConferenceList(null);
          }
        } else {
        }
      },
      (error) => {}
    );
  }
  const [isOpen, setIsOpen] = useState(true);
  function onClose() {
    setIsOpen(false);
  }
  function isOpenTo() {
    setIsOpen(true);
  }const handleSave = () => {
    // Select bileşeninin kapanmasını sağlamak için
    // burada gerekli kodu yazın
  };

  return (
    <div
      className="card"
      style={{
        width: "100%",
        border: "none",
        borderRadius: "12px",
        margin: "0px",
        padding: "0px",
      }}
    >
      <div
        className="card-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          borderBottom: "none",
          backgroundColor: "transparent",
          fontSize: "20px",

          paddingBottom: "5px",
        }}
      >
        Toplantılarım
      </div>
   
  
      <div
        className="card-body"
        style={{
          width: "100%",
        }}
      >
        {videoConferenceList && videoConferenceList.length > 0 ? (
          <div onClick={() => {}}>
            {videoConferenceList.map((item) => {
              return (
                <div>
                  <TabelItem
                    item={item}
                    companyName={item.companyAlias}
                    meetingDate={item.meetingDate}
                    employerProfileImageUrl={item.employerProfileImageUrl}
                  />
                </div>
              );
            })}
          </div>
        ) : videoConferenceList === null ||
          videoConferenceList === undefined ? (
          <div>
            {" "}
            <MatchNotFound title={""} newMatch={true} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {<LoadingDotsAnimation />}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoConferenceList;
