import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardFooter, Image, Button } from "@nextui-org/react";
import LoadingDotsAnimation from "../../LoadingDotsAnimation";
import { getToken } from "../../configs/cookie";
import { VideoConferenceService } from "../toolkit/services/videoConferenceServices";

const VideoConferenceDetail = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const token = getToken();
  const location = useLocation();
  const { dataMap } = location.state;
  const [meetingCandidateId, setMetingCandidateId] = useState(null);
  var videoConferansIdRef = useRef(null);
  function getInfo() {
    VideoConferenceService.getInfoCandidate().then((response) => {
      if (response && response.succeeded === true) {
        setMetingCandidateId(response.data.candidateId);
      }
    });
  }
  useEffect(() => {
    getInfo();
  }, []);

  const roomName = dataMap.get("roomName");
  const displayName = dataMap.get("displayName");
  const companyName = dataMap.get("companyName");
  // - - - - - - -- - - - - - --
  const meetingRoom = dataMap.get("meetingRoom");

  //  const videoConferansId = dataMap.get("videoConferansId");
  const meetingEmployerId = dataMap.get("meetingEmployerId");
  const meetingMatchId = dataMap.get("meetingMatchId");
  const meetingJobId = dataMap.get("meetingJobId");
  // const meetingCandidateId = dataMap.get("meetingCandidateId");
  const meetingMeetingDateTime = dataMap.get("meetingMeetingDateTime");
  const meetingCandidateNameSurname = dataMap.get(
    "meetingCandidateNameSurname"
  );
  const meetingPositionName = dataMap.get("meetingPositionName");
  const companyAlias = dataMap.get("companyAlias");
  //- - - - - - -- - - - - - --
  const handleMeetingError = (error) => {
    // Uyarı: Bant genişliği hatası veya başka bir hata durumunda kullanıcıya bilgi göster.
    setError(`Hata oluştu: ${error.message}`);
    toast.info(error.message);
  };
  function videoConfJoined() {
    VideoConferenceService.videoConfJoined(
      meetingJobId,
      meetingEmployerId,
      meetingCandidateId,
      videoConferansIdRef.current,
      meetingMatchId,
      meetingMeetingDateTime,
      meetingRoom,
      meetingCandidateNameSurname,
      meetingPositionName,
      companyAlias
    ).then((response) => {
      if (response && response.succeeded) {
        videoConferansIdRef.current = response.data;
      }
    });
  }

  function videoConfClosed(current) {
    var id = videoConferansIdRef.current;

    VideoConferenceService.videoConfClosed(videoConferansIdRef.current).then(
      (response) => {
        if (response && response.succeeded) {
          if (current) {
            navigate("/");
          }
        }
      }
    );
  }

  useEffect(() => {
    const onBeforeUnload = (ev) => {
      if (videoConferansIdRef.current) {
        videoConfClosed(false);
      }
      ev.returnValue = "Anything you wanna put here!";
      return "Anything here as well, doesn't matter!";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);
  return (
    <div
      style={{
        margin: "50px",
      }}
    >
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        {" "}
        <p
          style={{
            fontSize: "17px",
            color: "#001542",
            fontWeight: "500",
            marginBottom: "0px",
            paddingBottom: "5px",
          }}
        >
          <strong>{companyName}</strong> ile Video konferansınız başlatılmıştır
        </p>
      </div>
      <Card isFooterBlurred radius="lg" className="border-none">
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <JaaSMeeting
            domain={process.env.REACT_APP_SECRET_MEET_API}
            roomName={roomName}
            jwt={token}
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
            }}
            lang="tr"
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
            }}
            spinner={() => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "0px",
                }}
              >
                <LoadingDotsAnimation />
              </div>
            )}
            onApiReady={(externalApi) => {
              externalApi.getRoomsInfo().then((rooms) => {
                //   alert("rooms* * * " + JSON.stringify(rooms));
              });
              const participantEmployer = externalApi.on(
                "videoConferenceJoined",
                function (event) {
                  videoConfJoined();
                }
              );
              const participantJoinedCandidate = externalApi.on(
                "participantJoined",
                function (event) {
                  videoConfJoined();
                  externalApi.getRoomsInfo().then((rooms) => {});
                }
              );
              const participantLeft = externalApi.on(
                "participantLeft",
                function (event) {
                  videoConfClosed(false);
                }
              );
            }}
            onError={handleMeetingError} // Hata durumunda çağrılacak fonksiyon
            userInfo={{
              displayName: meetingCandidateNameSurname,
              isSingleUser: true,
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "550px";
              iframeRef.style.width = window.innerHeight;
              iframeRef.style.borderRadius = "8px";

              iframeRef.style.clipPath = "inset(0 0 0 0 round 0px 0px 8px 8px)";
            }}
            onReadyToClose={() => {
              videoConfClosed(true);
            }}
          />{" "}
        </div>
      </Card>
    </div>
  );
};

export default VideoConferenceDetail;
