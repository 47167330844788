//import {getToken} from "./cookie";

import { getToken } from "./cookie";

//export const baseUrl = "prod-ip";
//export const baseUrl = "http://localhost:3001";
export const baseUrl = "https://isarayansocket.bitonis.com";
//export const baseUrl = "https://testsocket.bitonis.com";
//export const baseUrl = "http://65.108.55.166:7201";

export const apiHeaders = () => {
   const token = getToken();

    if (token) {
        return {Authorization: token };
    }
    return {};
};
