import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/nav.css"; // Assuming you have CSS styles in this file
import { Route, NavLink, Routes } from "react-router-dom";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
  User,
} from "@nextui-org/react";
import { removeToken } from "../../configs/cookie";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../image/logo.png";
import { API_URL_API } from "../../apiUrl";
import NotFoundPage from "../../componnets/NotFoundPage";
import { authService } from "../toolkit/services/authServices";
import VideoConferenceList from "../Layout/VideoConferenceList";
import VideoConferenceDetail from "../Layout/VideoConferenceDetail";
export default function NavBarMenu() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [userData, setUserData] = useState(null);
  const [userImage, setUserImage] = useState("");

  useEffect(() => {
    getUserInfo();
  }, []);

  function getUserInfo() {
    setUserData(null);
    authService.getUserInfoBase().then(
      (userResponse) => {
        if (userResponse && userResponse.succeeded === true) {
          setUserData(userResponse.data);
          if (userResponse.data) {
            var url =
              process.env.REACT_APP_SECRET_APU_URL +
              "/images/candidate/profile/" +
              userResponse.data.candidateId +
              "/" +
              userResponse.data.profileImageUrl;

            setUserImage(url);
          }
        }
      },
      (error) => {}
    );
  }
  return (
    <div className="containerX">
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={`navigation ${isMenuOpen ? "active" : ""}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <ul>
              <li>
                <NavLink
                  exact
                  to={"/home"}
                  activeClassName={location.pathname === "/videoConferenceList"}
                >
                  <div>
                    <span
                      className="icon"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: "55px",
                          height: "55px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={userImage}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            border: "3px solid grey",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "180px",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        <span
                          className="title"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {userData && userData.name + " " + userData.surname}
                        </span>
                      </div>
                    </span>{" "}
                  </div>
                </NavLink>
              </li>
              <MenuItem
                url="/videoConferenceList"
                title="Toplantılarım"
                icon="videocam-outline"
                isActive={
                  location.pathname === "/videoConferenceList" ||
                  location.pathname === "/"
                }
              />
            
            </ul>
          </div>
        </div>

        <div className={`main ${isMenuOpen ? "active" : ""}`}>
          <div className="topbar">
            <div className="toggle" onClick={toggleMenu}>
              <ion-icon name="menu-outline"></ion-icon>
            </div>

            <div className="user">
              <div className="flex items-center gap-4">
                <Dropdown placement="bottom-end">
                  <DropdownTrigger>
                    <div
                      style={{
                        width: "55px",
                        height: "55px",
                      }}
                    >
                      <img
                        src={userImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: "3px solid orange",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      />
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Profile Actions" variant="flat">
                    <DropdownItem key="profile" className="h-14 gap-2">
                      <p
                        className="font-semibold"
                        style={{
                          padding: "0px",
                          margin: "0px",

                          marginBottom: "5px",
                          width: "100%",
                        }}
                      >
                        {userData && userData.name + userData.surname}
                      </p>
                      <p
                        className="font-semibold"
                        style={{
                          padding: "0px",
                          margin: "0px",

                          marginBottom: "5px",

                          width: "100%",
                        }}
                      >
                        {userData && userData.mobilephone}
                      </p>
                    </DropdownItem>

                    <DropdownItem
                      key="logout"
                      color="danger"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        removeToken();
                        window.location.reload();
                        toast.success("Başarılı Şekilde Çıkış Yapıldı", {
                          autoClose: 1000,
                          onClose: () => {
                            setTimeout(() => {
                              navigate("/");
                            }, 100);
                          },
                        });
                      }}
                    >
                      Çıkış Yap
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>{" "}
          <RouteDrawer />
        </div>
      </div>
    </div>
  );
}

const MenuItem = ({ url, title, icon, isActive }) => {
  return (
    <li className={isActive ? "hovered" : ""}>
      <NavLink exact to={url} activeClassName={isActive}>
        <span className="icon">
          <ion-icon name={icon}></ion-icon>
        </span>
        <span className="title">{title}</span>
      </NavLink>
    </li>
  );
};

const RouteDrawer = () => {
  return (
    <div>
      <div className="">
        <Routes>
          <Route path="/home" element={<VideoConferenceList />} />
          <Route path="/" element={<VideoConferenceList />} />
          <Route
            path="/videoConferenceList"
            element={<VideoConferenceList />}
          />{" "}
          <Route path="/videoConference" element={<VideoConferenceDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};
